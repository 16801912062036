module.exports = {
  comm: {
    home: 'Domů',
    pricing: 'Ceník',
    blog: 'Klonování hlasu&API', // 更新
    language: 'Jazyk:',
    select_language: 'Vyberte jazyk',
    txt2voice: 'Text na řeč',
    voice2txt: 'Řeč na text',
    voiceclone: 'Klonování hlasu',
    video2txt: 'Video na text',
    footer_help: 'Potřebujete pomoc? Pošlete nám email:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'Přihlásit se',
    login_desc: 'Přihlaste se pro přístup k vašemu účtu generátoru hlasu TikTok',
    logout: 'Odhlásit se',
  },
  txt2voice: {
    title: 'Generátor hlasu TikTok: Generujte zábavné AI hlasy TikTok',
    description: 'Generujte a stahujte zdarma zábavné AI hlasy TikTok, jako je hlas jessie, hlas C3PO, hlas ghostface...',
    keywords: 'Generátor hlasu TikTok, AI hlas TikTok, Generátor hlasu Tik Tok',
    main_title_p01: 'Generátor hlasu TikTok',
    main_title_p02: 'Generujte zábavné hlasy TikTok: hlas jessie, hlas C3PO, hlas ghostface',
    main_textarea_holder: 'Zadejte nebo vložte text sem',
    main_genvoice: 'generovat',
    main_generating: 'generování',
    main_input_empty: 'prosím zadejte nebo vložte text',
    daily_usage_limit_msg: '1) Bylo dosaženo denního maxima použití. Prosím, vraťte se zítra. 2) Pokud naléhavě potřebujete více použití, kontaktujte nás e-mailem.',
    text_max_prompt1: 'Prosím omezte počet slov na',
    text_max_prompt2: 'slova nebo méně!',
    popup_nologin_title: 'Upgradujte svůj plán předplatného',
    popup_nologin_desc: 'Odemkněte více času pro generování hlasu upgradem na prémiové předplatné.',
    popup_nologin_btntext: 'Prozkoumat plány předplatného',
    popup_nosub_title: 'Upgradujte svůj plán předplatného',
    popup_nosub_desc: 'Odemkněte více času pro generování hlasu upgradem na prémiové předplatné.',
    popup_nosub_btntext: 'Prozkoumat plány předplatného',
    popup_sublimit_title: 'Předplatné vyčerpáno, prosím upgradujte',
    popup_sublimit_desc: 'Vylepšete svůj zážitek s našimi prémiovými funkcemi a neomezeným přístupem.',
    popup_sublimit_btntext: 'Přejít na nákup více',
  },
  pricing: {
    new_features_alert: "📣 Spustili jsme 🎙️ Klonování hlasu a 🤖 API služby pro placené členy! (Po platbě nám pošlete e-mail na tiktokaivoicetool@gmail.com pro pomoc s těmito funkcemi). Kromě toho nám neváhejte poslat e-mail s jakýmikoli požadavky na přizpůsobení😃", // 新增
    pricing_title: 'Od jednotlivých tvůrců po největší podniky, máme plán pro vás.',
    pricing_desc: 'Více než 100 přirozených, lidských hlasů. Více než 15 jazyků. Nejnižší cena pro téměř každého',
    sub_free_name: 'Zdarma',
    sub_free_desc: 'Pro jednotlivce, kteří chtějí vyzkoušet nejmodernější AI audio',
    sub_free_content: [
      'Limit 1000 znaků měsíčně',
      '5 generování denně',
    ],
    sub_starter_name: 'Starter',
    sub_starter_desc: 'Pro tvůrce, kteří vytvářejí prémiový obsah pro globální publikum',
    sub_starter_content: [
      '1 klon hlasu',
      'Limit 300 000 znaků měsíčně (přibližně 7 hodin audia)',
      'Neomezené stahování',
      'E-mailová podpora do 72 hodin',
      'Prioritní přístup k novým hlasům a funkcím',
    ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'Pro tvůrce, kteří rozšiřují svou produkci obsahu',
    sub_pro_content: [
      'API přístup',
      '3 klony hlasu',
      'Limit 1 000 000 znaků měsíčně (přibližně 24 hodin audia)',
      'Neomezené stahování',
      'E-mailová podpora do 48 hodin',
      'Prioritní přístup k novým hlasům a funkcím',
    ],
    period_year: 'Ročně',
    period_month: 'Měsíčně',
    period_month_short: 'měs',
    billed_year: 'Účtováno ročně',
    most_popular: 'Nejoblíbenější',
    discount_quantity: 'Ušetřete $48',
    buy_btntext: 'Začít',
    pay_succ: 'Platba úspěšná!',
    pay_succ_desc: 'Děkujeme za váš nákup. Transakce byla úspěšně zpracována.',
    pay_succ_btntext: 'Začít používat TikTok Voice',
    pay_fail: 'Platba selhala!',
    pay_fail_desc: "Omlouváme se, nemohli jsme zpracovat vaši transakci. Zkuste to prosím znovu nebo kontaktujte náš tým podpory (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'Zpět na domovskou stránku',
  },
  setting: {
    setting: 'Nastavení',
    setting_title: 'Zde spravujte svůj účet, využití a předplatné.',
    basic_info: 'Základní informace', // 新增，放在 setting_title 下方
    user_name: 'Jméno',
    user_email: 'E-mail',
    user_account: 'Účet',
    user_subscript: 'Aktuální plán',
    user_usage: 'Využití',
    manage_subscript: 'Spravovat předplatné',
    use_limit_promote: "Použili jste 5000 znaků z vašeho měsíčního limitu 10000 znaků.",
    not_logged_in: 'Nejste přihlášeni', // 新增
    go_to_login: 'Přejít na přihlášení >>', // 新增
  },
  login: { // 新增 login 对象，放在 setting 下方
    title: "Generátor hlasu TikTok",
    subtitle: "Přihlaste se pro přístup k vašemu účtu generátoru hlasu TikTok",
    alreadyLoggedIn: "Již jste přihlášeni",
    goToHome: "Přejít na domovskou stránku"
  },
  faq: {
    quest1: 'Generátor hlasu TikTok - Nejlepší nástroj pro AI hlasy TikTok',
    answer1_1: 'Jedná se o nástroj pro generování AI hlasů TikTok, vyvinutý nejnovější technologií tts TikTok. Může generovat hlasy TikTok, jako je hlas jessie (ženský hlas), hlas siri, hlas ghostface, hlas C3PO (robotický hlas), hluboký hlas (vypravěč), hlas skye, teplý hlas, hlas bestie, hlas hrdiny (hlas chiris), empatický hlas, vážný hlas, hlas joey, hlas stitch, hlas stormtroopera (Star Wars), hlas Rocket (Guardians of the Galaxy).',
    answer1_2: 'AI hlasy TikTok, které budou brzy podporovány, zahrnují: hlas derek, hlas trickster, hlas austin butler, hlas reportéra, hlas adam, hlas psa, hlas miley cyrus, hlas alexa, hlas mimozemšťana, hlas zvířete, hlas dítěte, hlas počítače, hlas chipmunk, hlas echo, hlas npr.',
    answer1_3: 'Kromě toho nástroj pro generování hlasů TikTok podporuje několik dalších jazyků, včetně čínštiny, japonštiny, korejštiny, vietnamštiny, thajštiny, hindštiny, perštiny, ruštiny, němčiny, francouzštiny, rumunštiny, češtiny, španělštiny, portugalštiny, bengálštiny, italštiny, arabštiny, urdštiny, tradiční čínštiny a malajštiny.',
    answer1_4: 'Pokud potřebujete naléhavě určitý hlas, pošlete mi prosím email.',

quest2: 'Jaké jsou výhody generátoru hlasu TikTok?',
    answer2_1: '- Nástroj generátoru hlasu TikTok může generovat různé typy hlasů, které se často používají ve videích TikTok.',
    answer2_2: '- Je to nejnovější generátor AI hlasů, který může generovat hlasy tts TikTok podobné lidským.',
    answer2_3: '- Je pohodlnější pro úpravu videí na PC.',
    answer2_4: '- Můžete používat některé hlasy, které v současné době nelze nalézt v aplikaci TikTok tts.',

quest3: 'Jak používat generátor hlasu TikTok?',
    answer3_1: 'Generátor hlasu TikTok je velmi snadno použitelný:',
    answer3_2: '- Vyberte jazyk a akcent hlasu.',
    answer3_3: '- Zadejte text, který má být převeden na řeč, do vstupního pole.',
    answer3_4: '- Stiskněte tlačítko generovat a počkejte několik sekund.',
    answer3_5: '- Přehrávejte nebo stahujte AI hlas TikTok.',

quest4: 'Jaké jsou nejznámější a nejvtipnější hlasy TikTok?',
    answer4_1: 'Nejznámější mužský hlas TikTok je hluboký hlas, oficiálně nazývaný vypravěč.',
    answer4_2: 'Pro nejvtipnější hlas TikTok osobně doporučuji hlas ghostface a hlas C3PO. Tyto hlasy se často používají pro dabing zábavných videí TikTok, protože jsou snadno rozpoznatelné a mohou rychle přitáhnout diváky.',

quest5: 'Jaké jsou nejotravnější hlasy TikTok?',
    answer5_1: 'Myslím si, že neexistuje opravdu "otravný" hlas TikTok.',
    answer5_2: 'To více záleží na kontextu a obsahu, ve kterém je hlas používán, což může některé lidi nepříjemně ovlivnit. Například někteří lidé mohou považovat hlas jessie za otravný, protože se v určité době na TikToku objevoval příliš často. Nicméně, díky svému tónu a kvalitě je to ve skutečnosti fantastický efekt hlasu TikTok. Kromě toho je hlasová herečka TikTok za tímto hlasem velmi profesionální a také publikuje vlastní videa na TikTok.',
    answer5_3: 'Proto navrhuji, aby všichni odložili obavy a předsudky, několikrát si poslechli hlasy z tohoto nástroje a trpělivě našli ten nejlepší efekt hlasu TikTok pro sebe.',

quest6: 'Jak používat generátor hlasu TikTok k vytvoření hlasu jessie?',
    answer6_1: '- V prvním rozbalovacím menu nástroje generátoru hlasu TikTok vyberte English (US), pak v druhém rozbalovacím menu vyberte hlas Jessie (ženský hlas).',
    answer6_2: '- Zadejte text do vstupního pole a klikněte na tlačítko generovat.',
    answer6_3: '- Počkejte několik sekund nebo až deset sekund, uslyšíte AI hlas. V ovládacím panelu najděte tlačítko se šipkou dolů a klikněte na něj pro stažení.',

quest7: 'Jak používat generátor hlasu TikTok k vytvoření hlasu C3PO?',
    answer7_1: '- V prvním rozbalovacím menu nástroje generátoru hlasu TikTok vyberte English (US), pak v druhém rozbalovacím menu vyberte hlas C3PO (robotický hlas).',
    answer7_2: '- Zadejte text do vstupního pole a klikněte na tlačítko generovat.',
    answer7_3: '- Počkejte několik sekund nebo až deset sekund, uslyšíte AI hlas. V ovládacím panelu najděte tlačítko se šipkou dolů a klikněte na něj pro stažení.',

quest8: 'Jak používat generátor hlasu TikTok k vytvoření hlasu ghostface?',
    answer8_1: '- V prvním rozbalovacím menu nástroje generátoru hlasu TikTok vyberte English (US), pak v druhém rozbalovacím menu vyberte hlas ghostface.',
    answer8_2: '- Zadejte text do vstupního pole a klikněte na tlačítko generovat.',
    answer8_3: '- Počkejte několik sekund nebo až deset sekund, uslyšíte AI hlas. V ovládacím panelu najděte tlačítko se šipkou dolů a klikněte na něj pro stažení.',

quest9: 'Jak používat generátor hlasu TikTok k vytvoření hlasu siri?',
    answer9_1: '- V prvním rozbalovacím menu nástroje generátoru hlasu TikTok vyberte English (US), pak v druhém rozbalovacím menu vyberte hlas siri.',
    answer9_2: '- Zadejte text do vstupního pole a klikněte na tlačítko generovat.',
    answer9_3: '- Počkejte několik секунд nebo až deset sekund, uslyšíte AI hlas. V ovládacím panelu najděte tlačítko se šipkou dolů a klikněte na něj pro stažení.',

quest10: 'Jak přidat efekt hlasu TikTok na video TikTok?',
    answer10_1: 'Pokud chcete vědět, jak používat oficiální hlasy TikTok na TikToku, brzy napíšu speciální blogový příspěvek na toto téma.',
    answer10_2: 'Zde vysvětlím, jak zveřejnit svůj hlas na TikToku po jeho vytvoření a stažení z generátoru hlasu TikTok.',
    answer10_3: '1. Pokud jste vytvořili hlas TikTok na svém PC a chcete jej nahrát na TikTok nebo do jakékoli jiné aplikace pro úpravu videí na svém telefonu, musíte přenést soubor hlasu do svého telefonu. Pro iPhone můžete použít airDrop k přenosu. Pro telefony Android nejsem s tímto procesem příliš obeználen, ale určitě najdete způsoby a nástroje, jak to udělat.',
    answer10_4: '2. Jakmile bude soubor hlasu přenesen do vašeho telefonu, můžete otevřít TikTok:',
    answer10_5: '- Klepněte na tlačítko "+" ve spodní části rozhraní, vyberte video ve svém telefonu.',
    answer10_6: '- Po nahrání videa najděte čtvercovou ikonu na pravé straně obrazovky a vstupte na stránku úprav.',
    answer10_7: '- Ve spodní části stránky najděte tlačítko "přidat hlas" a klepněte na něj pro výběr právě přeneseného souboru hlasu.',
    answer10_8: '- V tomto rozhraní můžete provést několik úprav videa a poté klepněte na tlačítko v pravém horním rohu pro jeho zveřejnění.',

quest11: 'Je TikTok Voice zdarma?',
    answer11: 'Ano, jedná se o bezplatný nástroj pro generování AI hlasů TikTok z textu na řeč.',

quest12: 'Kde mohu vytvořit populární hlas TikTok pro své video TikTok?',
    answer12: 'Můžete navštívit https://tiktokvoice.net/ a přidat AI hlas TikTok do svého videa.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}